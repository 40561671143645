html {
   font-size: 6px;
}

body {
   font-family: 'Montserrat', sans-serif;
   font-size: 16px;
   overflow: hidden;
   background: #010101;
   color: #fff;
   margin: 0;
}

html,
body,
#root,
.wrapper,
.screens {
   height: 100%;
}

a  {
   display: block;
   text-decoration: none;
}

p {
   font-size: 16px;
   font-weight: 400;
   line-height: 180%;
   margin: 5rem 0;
}

.only-mobile {
   display: none
}

.hover-effect {
   display: inline-block;
}

.columns {
   display: flex;
   width: calc(100% + 8rem);
   margin-left: -4rem;
}

.columns>div {
   flex: 1;
   padding: 0 4rem;
}

.introduction-text {
   display: flex;
   margin-bottom: 14rem;
   /*
   width: calc(100% + 8rem);
   margin-left: -4rem;
   */

   .we-are-hiring {
      margin-bottom: -4rem;
      color: #cd1359;
   }

   .content {
      background: #ffffff;
      color: #000;
      padding: 10rem;
      width: 100%;
      max-width: 550px;
      /* margin: 0 4rem; */
      /* box-sizing: border-box; */
   }

   .image {
      flex: 1;
      background: url(/assets/img/alfonso_playing.jpg) center;
      background-size: cover;
      overflow: hidden;
      /* margin: 0 4rem; */

      img {
         height: 100%;
      }
   }

   h2 {
      margin-bottom: 8rem;
      font-weight: 500;
   }

   p {
      font-size: 22px;
   }
}

.mobile-video-cover {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 1;
   background-size: cover !important;
   overflow: hidden;
   opacity: 0;
   transition: opacity 2s ease-in-out;
}

.mobile-video-cover.show {
   opacity: 1;
}

.mobile-play-button {
   background: #000000 url(/assets/img/play.png) no-repeat right 12px center;
   background-size: 12px;
   border: 1px #404040 solid;
   position: relative;
   z-index: 2;
   /*
   position: absolute;
   top: 17rem;
   right: 27px;
   */
   text-align: center;
   /* transform: translateX(-50%); */
   padding: 3rem 6rem 3rem 3rem;
   z-index: 2;
   display: none;
   border-radius: 25px;
   margin-bottom: 5rem;

   p {
      font-size: 12px;
      line-height: initial;
      margin: 0 !important;
      padding: 0 !important;
      font-weight: 600;
   }
}

.work-minsait {
   .image {
      background: url(/assets/img/minsait.jpg) center;
      background-size: cover;
   }
}

.contact {
   .image {
      background: url(/assets/img/contact.jpg) center;
      background-size: cover;
   }

   .msg-send-ok {
      margin-bottom: -4rem;
      float: left;
      margin-top: -2rem;
      opacity: 0;
      transition: all 0.3s ease-in-out;

      p {
         margin: 0;
         color: #cd1259;
         margin-top: 4px;
      }
   }

   .msg-send-ok.show {
      opacity: 1;
      margin-top: 0;
   }
}

.scroll-arrow {
   text-align: center;
   position: absolute;
   bottom: 8rem;
   left: 50%;
   z-index: 9;
   transform:translateX(-50%);

   img {
      margin: auto;
      transform: rotate(-90deg);
      animation: 1s infinite alternate arrowBounce;
   }
}

@keyframes arrowBounce {
	0% {
		transform: rotate(-90deg) translateX(0);
	}
	100% {
      transform: rotate(-90deg) translateX(-16px);
	}
}

h3 {
   font-size: 30px;
   font-weight: 400;
   text-transform: uppercase;
}

.blink {
   opacity: 0.4;
   animation: blink-animation 5s infinite ease-in-out;
}

@keyframes blink-animation {
   0% {
      opacity: 0.4;
   }
   5% {
      opacity: 1;
   }
   10% {
      opacity: 0.4;
   }
   15% {
      opacity: 1;
   }
   20% {
      opacity: 0.4;
   }
}

@media only screen and (max-width: 1200px) {
   body {
      .main-menu {
         .menu-links {
            a {
               font-size: 70px;
            }
         }
      }
   }
}

@media only screen and (max-width: 768px) {
   body {
      .columns,
      .wrapper-box .row {
         display: block;
         width: 100%;
         margin-left: 0;
      }

      .columns > div {
         padding: 0;
      }

      .columns.maps > div,
      .work-minsait .columns > div,
      .about .columns > div {
         padding: 0;
         margin-bottom: 10rem;
      }

      .wrapper-box {
         padding: 22rem 10rem 22rem;
      }

      .sections .section {
         h1 {
            font-size: 60px;
            line-height: initial;
            margin-bottom: 4rem;
         }
      }
   }
}

@media only screen and (max-width: 769px) and (min-width: 500px) {
   body {
      .wrapper-box.about-team {
         .col {
            width: 50%;
            float: left;
            box-sizing: border-box;
            margin-bottom: 10rem;

            .description {
               height: 200px;
            }
         }

         .row {
            width: calc(100% + 10rem);
            margin-left: -5rem;
            margin-right: -5rem;
         }
      }
   }
}

@media only screen and (max-width: 500px) {

   body {
      .fixed-topbar {
         padding: 4rem 5rem 0;
      }

      .main-menu-icon {
         right: 5rem;
         top: 35px;
      }

      .main-menu {
         .menu-links {
            left: 8rem;

            a {
               font-size: 40px;
               margin-bottom: 1rem;
            }
         }

         .claim {
            width: 220px;
            text-align: center;
         }
      }

      .sections .section {
         left: 4rem;
         top: initial;
         bottom: 12rem;
         transform: none;

         a {
            display: none;
         }

         h1 {
            font-size: 40px;
         }

         p {
            padding-right: 4rem;
            margin-top: 0;
         }
      }

      .wrapper-box {
         padding: 0;

         h2 {
            padding-left: 4rem;
            padding-right: 4rem;
            font-size: 32px;
            margin-bottom: 4rem;
            margin-top: 8rem;
         }
      }

      .introduction-text {
         margin-bottom: 8rem;

         .content {
            padding: 6rem 4rem 1rem;
         }

         p {
            font-size: 20px;
         }

         h2 {
            padding-left: 0rem;
            padding-right: 0rem;
            margin-top: 0rem;
         }
      }

      .columns.maps > div,
      .work-minsait .columns > div,
      .about .columns > div {
         padding: 0 4rem;
         margin-bottom: 10rem;
      }

      .fixed-footer {
         margin-top: 16rem;
         position: relative;
         bottom: 0rem;
      }

      body .scroll-view .scroll-content {
         background-size: 700px;
      }

      .profile {
         margin-top: 12rem;
      }

      .contact {
         .introduction-text .content {
            padding: 6rem 4rem 8rem;
         }

         p {
            min-height: initial;
         }
      }
   }
}

@media (hover: none) and (pointer: coarse) {
   .custom-cursor {
      display: none;
   }

   .fade-in-top {
      transition: all 0.4s ease-in-out;
   }
}


@media (hover: none) and (pointer: coarse) and (max-width: 769px) {
   .only-mobile {
      display: block
   }

   .home {
      .sections .section {
         bottom: 4rem;

         h1 {
            font-size: 22px;
         }
      }
   }

   .mobile-play-button {
      display: inline-block;
   }
}